<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="''">
          <template v-slot:preview>
            <b-card
              no-body
              no-footer
              class="full-width"
            >
              <!-- BEGIN WIZARD -->

              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!-- NAVIGATION -->
                <div
                  class="wizard-nav bg-nav-light"
                  v-if="renderComponent"
                >
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Voucher</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- CONTENT -->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-10 px-lg-5">
                      <div class="col-xl-12">
                        <form class="form mt-0">
                          <div
                            class="full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-md-12 full-width">
                              <b-row class="mb-3">
                                <b-col cols="4">
                                  <b-form-group>
                                    <template>
                                      <label>Tên:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="customerType.name"
                                      :state="validateState('name')"
                                      required
                                      placeholder="Nhập tên loại thành viên"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-live-feedback">Vui lòng nhập tên loại khách hàng</b-form-invalid-feedback>

                                  </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                  <b-form-group>
                                    <template>
                                      <label>Số tiền cần tiêu dùng:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-input
                                      size="sm"
                                      v-model="$v.customerType.upgradeCost.$model"
                                      :state="validateState('upgradeCost')"
                                      required
                                      v-mask="currency"
                                      placeholder="Nhập số tiền"
                                    ></b-form-input> -->
                                    <b-input-group>

                                      <b-form-input
                                        class="input-style text-right"
                                        size="sm"
                                        type="text"
                                        v-model="$v.customerType.upgradeCost.$model"
                                        :state="validateState('upgradeCost')"
                                        v-mask="currency"
                                        placeholder="Số tiền tiêu dùng"
                                      ></b-form-input>
                                      <b-input-group-append>
                                        <span
                                          class="input-group-text p-2 pl-3 pr-3"
                                          style="color: #ffffff; background: #2ba0cb"
                                          id="inputGroup-sizing-sm"
                                        >VND</span>
                                      </b-input-group-append>
                                      <b-form-invalid-feedback id="input-live-feedback">Vui lòng nhập số tiền tiêu dùng</b-form-invalid-feedback>
                                    </b-input-group>

                                  </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                  <b-form-group>
                                    <template>
                                      <label>Trạng thái:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customerType.status"
                                      :options="listStatus"
                                      :state="validateState('status')"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-live-feedback">Vui lòng chọn trạng thái</b-form-invalid-feedback>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group>
                                    <template>
                                      <label>Mô tả:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="customerType.description"
                                      :state="validateState('description')"
                                      :placeholder="'Thêm mô tả'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                    <b-form-invalid-feedback id="input-live-feedback">Vui lòng nhập mô tả</b-form-invalid-feedback>

                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>

                            <b-col cols="12">
                              <b-button
                                style="width: 70px"
                                variant="primary"
                                size="sm"
                                type="submit"
                                v-if="checkPermission('CUSTOMER_UPDATE')"
                                @click="updateCustomer"
                              >Lưu</b-button>
                              <b-button
                                class="ml-2"
                                style="width: 70px"
                                @click="$router.go(-1)"
                                variant="secondary"
                                size="sm"
                              >Hủy</b-button>
                            </b-col>
                          </div>
                          <!-- <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col
                              cols="12"
                              class="full-width"
                            >
                              <b-row>
                                <CustomerBill :customerId="$route.query.phoneNo" />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col
                              cols="12"
                              class="full-width"
                            >
                              <b-row>
                                <CustomerOrder :customerId="$route.query.id" />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col
                              cols="12"
                              class="full-width"
                            >
                              <b-row>
                                <CustomerTradeIn :customerId="$route.query.id" />
                              </b-row>
                            </b-col>
                          </div> -->
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END WIZARD -->
            </b-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}

.list .list-item {
  flex: 0 0 calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
}
.alert-custom {
  border: 1px solid #000;
  box-shadow: 1px 1px 20px #ccc;
}

.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}

.card-header-tabs {
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.label-header-form {
  font-size: 16px;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
textarea.form-control {
  border: 1px solid #ced4da;
}
.bg-nav-light {
  background-color: #eef0f8;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { checkPermission } from '@/utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';
// import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
// import CustomerBill from '../../components/CustomerBill.vue';
// import CustomerOrder from '../../components/CustomerOrder.vue';
// import CustomerTradeIn from '../../components/CustomerTradeIn.vue';
import { TIME_TRIGGER } from '@/utils/constants';

// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import {
  xoa_dau,
  makeToastSuccess,
  makeToastFaile,
  convertPrice,
} from '@/utils/common';
import { currencyMask, unMaskPrice } from '../../../utils/common';

// import {
//   CUSTOMER_GENDER,
//   CUSTOMER_TYPE,
//   CUSTOMER_APPELLATION,
// } from '@/utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      currency: currencyMask,
      isNew: true,
      listStatus: [
        { id: null, name: 'Chọn trạng thái' },
        { id: false, name: 'Tắt' },
        { id: true, name: 'Bật' },
      ],
      customerType: {
        id: null,
        name: null,
        upgradeCost: null,
        description: null,
        status: null,
      },
      renderComponent: false,
    };
  },
  validations: {
    customerType: {
      status: { required },
      upgradeCost: { required },
      name: { required },
      description: { required },
    },
  },
  components: {
    KTCodePreview,
    // datePicker,
    // Autosuggest,
    // CustomerBill,
    // CustomerOrder,
    // CustomerTradeIn,
  },
  mounted() {
    if (this.checkPermission('CUSTOMER_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: 'Danh sách cấu hình loại khách hàng',
          route: '/customer-type',
        },
        { title: 'Cập nhật cấu hình loại khách hàng' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Khách hàng', route: '/customers' },
        { title: 'Danh sách khách hàng', route: '/customers' },
        { title: 'Thông tin khách hàng' },
      ]);
    }

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });
    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, TIME_TRIGGER);
    });
  },
  created() {
    this.id = this.$route.query.id;
    this.getListEmployee();
    this.getCities();
    this.getCustomerTypeSettingById();
    this.getStatisticalBillCustomer();
  },
  computed: {
    listBrand() {
      if (this.customer.brands && this.customer.brands.length) {
        return this.customer.brands.map((item) => item.name).join(', ');
      }
      return '';
    },
  },
  methods: {
    convertPrice,
    checkPermission,
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.customerType[name];
        return $dirty ? !$error : null;
      }
    },
    updateCustomer: function () {
      this.isNew = false;

      this.$v.customerType.$touch();
      if (this.$v.customerType.$anyError) {
        return;
      }
      this.customerType['upgradeCost'] = unMaskPrice(
        this.customerType['upgradeCost'] || 0,
      );
      const data = {
        ...this.customerType,
      };
      ApiService.setHeader();
      ApiService.put(`customer/setting-type`, data)
        .then(() => {
          makeToastSuccess('Cập nhật thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getListEmployee: function () {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          const store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    getCustomerTypeSettingById: function () {
      ApiService.get(`customer/setting-type?id=${this.id}`).then((response) => {
        const { dataset } = response.data.data;
        this.customerType = {
          ...dataset[0],
        };
        setTimeout(() => {
          this.renderComponent = true;
        }, TIME_TRIGGER);
      });
    },
    getStatisticalBillCustomer: function () {
      if (this.phoneNo) {
        ApiService.setHeader();
        ApiService.get(
          `customer/statistical-bill?customerPhone=${this.phoneNo}`,
        ).then((response) => {
          const { employees, store, totalAmountBuyedBill, brands } =
            response.data.data;
          this.customer = {
            ...this.customer,
            employees,
            store,
            totalAmountBuyedBill,
            brands,
          };
        });
      }
    },
    getCities: function () {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistricts: function (reload) {
      if (reload) {
        this.customer.district = null;
        this.customer.ward = null;
      }

      if (!this.customer.city) {
        this.listDistrict = [];
        this.listWard = [];
        return;
      }

      ApiService.setHeader();
      ApiService.get(`city/${this.customer.city}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWards: function (reload) {
      if (reload) {
        this.customer.ward = null;
      }

      if (!this.customer.district) {
        this.listWard = [];
        return;
      }

      if (this.customer.district) {
        ApiService.setHeader();
        ApiService.get(`city/${this.customer.district}/ward`).then((data) => {
          this.listWard = data.data.data;
        });
      }
    },
    onSelectedEmployee(option) {
      this.customer.inChargeEmployeeName = option.item.name;
      this.customer.inChargeEmployeeId = option.item.id;
    },
    onInputChangeEmployee(text = '') {
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
